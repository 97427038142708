
















import { Component, Vue } from "vue-property-decorator";
import { getCSRFToken } from "../../services/actions";
import PasswordResetRequestForm from "@views/components/PasswordResetRequestForm.vue";
import axios, { AxiosError, AxiosResponse } from "axios";

@Component({ components: { PasswordResetRequestForm } })
export default class PasswordResetRequest extends Vue {}
