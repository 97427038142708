




























































































































import { Component, Vue } from "vue-property-decorator";
import { getCSRFToken, extractErrorMessage } from "../../services/actions";
import { rules } from "../../util/validation";
import axios, { AxiosError, AxiosResponse } from "axios";
import get from "lodash/get";
import flatten from "lodash/flatten";
import { scrollBehavior } from "../../util/dom";
import has from "lodash/has";
import Emitter from "../../services/events/event-bus";

import {
  MAX_EMAIL_ADDRESS_LENGTH,
  MIN_EMAIL_ADDRESS_LENGTH
} from "../../services/config/constants";

enum Status {
  IDLE = "idle",
  SUBMITTING = "submitting",
  SUCCESS = "success"
}

@Component
export default class PasswordResetRequestForm extends Vue {
  status: Status = Status.IDLE;
  loading: boolean = false;
  email: string = "";
  csrfToken: string = "";
  errors: string[] = [];
  formerrors: Record<string, string[]> = {};
  isopen: boolean = true;

  mounted() {
    this.reset();
  }

  get constants() {
    return {
      MAX_EMAIL_ADDRESS_LENGTH,
      MIN_EMAIL_ADDRESS_LENGTH
    };
  }

  onformupdate($evt: Event) {
    const { target: $target } = $evt;
    if ($target instanceof HTMLInputElement) {
      this.validation();
    }
  }

  getfielderrors(fieldname: string) {
    if (has(this.formerrors, fieldname)) {
      const errors = get(this.formerrors, fieldname, []) as string[];
      return errors.length ? errors[0] : "";
    }

    return "";
  }

  validation() {
    this.formerrors = ["email"].reduce(
      (errorbag: Record<string, string[]>, field: string) => {
        switch (field) {
          case "email": {
            const isvalid = rules.string.email(get(this.$data, field));
            return isvalid
              ? errorbag
              : { ...errorbag, email: ["Valid email address is required"] };
          }
          default: {
            return errorbag;
          }
        }
      },
      {} as Record<string, string[]>
    );
  }

  submit() {
    this.errors = [];
    this.status = Status.SUBMITTING;
    this.loading = true;

    const { email, csrfToken } = this.$data;

    this.validation();

    if (Object.keys(this.formerrors).length) {
      this.errors = flatten(Object.values(this.formerrors));
      this.status = Status.IDLE;
      this.loading = false;
      scrollBehavior("#user-password-reset-request-errors");
      return;
    }

    axios({
      url: "/api/v1/users/password-reset-request",
      method: "POST",
      data: { email, _csrf: csrfToken }
    })
      .then((response: AxiosResponse<string>) => {
        return this.getCSRFToken();
      })
      .then(() => {
        this.status = Status.SUCCESS;
        this.loading = false;
      })
      .catch((err: AxiosError) => {
        this.status = Status.IDLE;
        this.loading = false;
        this.errors = [extractErrorMessage(err)];
        scrollBehavior("#user-password-reset-request-errors");
        console.error("password-reset-request error", err);
      });
  }

  exit() {
    axios({
      url: `/api/v1/users/authentication`,
      method: "DELETE",
      headers: {
        "X-CSRF-Token": this.csrfToken
      }
    })
      .then((response: AxiosResponse) => {
        this.reset();
        Emitter.$emit("entrance-pass-revoked");
        this.loading = false;
        window.location.assign("/login");
      })
      .catch((err: AxiosError) => {
        this.getCSRFToken();
        this.status = Status.IDLE;
        this.loading = false;
        this.errors = [extractErrorMessage(err)];
        scrollBehavior("#user-password-reset-request-errors");
        console.debug("[logout request] Error", err);
      });
  }

  reset() {
    this.email = "";
    this.csrfToken = "";
    this.status = Status.IDLE;
    this.loading = false;
    this.errors = [];
    this.getCSRFToken();
    const $form = document.getElementById(
      "user-password-reset-request"
    ) as HTMLFormElement;

    if ($form) {
      $form.reset();
      $form.noValidate = true;
      $form.noValidate = false;
    }
  }

  beforeDestroy() {
    this.exit();
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }
}
